import { useAuth0 } from "@auth0/auth0-react";
import ClearIcon from "@mui/icons-material/Clear";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ExploreIcon from "@mui/icons-material/Explore";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import LogoutIcon from "@mui/icons-material/Logout";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState, useSyncExternalStore } from "react";
import {
	Link,
	useLocation,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";
import logo from "./images/SP logo early.png";
import "./styles.css";
import { Avatar } from "@mui/material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Menu,
	Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import clsx from "clsx";
import NamespaceDialog from "../NamespaceDialog";
import SpAvatar from "../SpAvatar";
import { handleGetAccessToken } from "../../Helpers/auth";
import useSelectedDashboard from "../../Hooks/useSelectedDashboard";
import {
	actions as dashboardActions,
	store as dashboardStore,
} from "../../Stores/Dashboard";
import { store as meStore } from "../../Stores/Me";
import { store as namespaceStore } from "../../Stores/Namespace";
import RequiresPermission from "../RequirePermission";
import Advanced from "./Advanced";
import DashboardMenus from "./DashboardMenus";

function TopBar({ selectedNamespace }) {
	const [anchorElUser, setAnchorElUser] = useState(null);
	const [showConfirmLogout, setShowConfirmLogout] = useState(false);
	const [showNamespaceSelection, setShowNamespaceSelection] = useState(false);
	const [_, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const location = useLocation();

	const isExploreActive = location.pathname === "/explore";
	const isExportActive = location.pathname === "/exports";

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const theme = useTheme();
	const { logout } = useAuth0();

	const selectedDashboard = useSelectedDashboard(selectedNamespace?.code);

	const {
		user,
		permissions,
		permissionsV2,
		sudodAs,
		isFetched: isUserFetched,
	} = useSyncExternalStore(meStore.subscribeToMe, meStore.getMe);

	const { available: availableNamespaces } = useSyncExternalStore(
		namespaceStore.subscribeToNamespaces,
		namespaceStore.getNamespaces,
	);

	const { isFetching: isFetchingDashboards, isFetched: hasFetchedDashboards } =
		useSyncExternalStore(
			dashboardStore.subscribeToDashboards,
			dashboardStore.getDashboards,
		);

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleMenuLogoutClick = () => {
		handleCloseUserMenu();
		setShowConfirmLogout(true);
	};

	const handleExitClick = () => {
		window.sessionStorage.removeItem("sudodAs");
		window.location.assign(
			`${window.location.origin}/users?n=${selectedNamespace?.code}`,
		);
	};

	const handleCancelLogout = () => {
		setShowConfirmLogout(false);
	};

	//Fetch all authorized dashboards
	useEffect(() => {
		if (!isFetchingDashboards && !hasFetchedDashboards) {
			handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
				(token) => {
					dashboardActions.fetchDashboards(token);
				},
			);
		}
	}, [
		getAccessTokenSilently,
		loginWithRedirect,
		isFetchingDashboards,
		hasFetchedDashboards,
	]);

	return (
		<>
			<AppBar position="static">
				<Toolbar disableGutters variant="dense" sx={{ mx: "15px" }}>
					<Grid container sx={{ width: "100%" }} alignItems="center">
						<Grid size={{ xs: 3 }} display="flex">
							<Link to="/">
								<img
									src={logo}
									width="158px"
									height="28px"
									alt="Sailplan"
									style={{
										verticalAlign: "middle",
									}}
								/>
							</Link>
						</Grid>
						<Grid size={{ xs: 6 }} display="flex" justifyContent="center">
							{!hasFetchedDashboards || isFetchingDashboards ? (
								<Grid container justify="space-around" spacing={3}>
									<BounceLoader
										color={theme.palette.primary.loader}
										size={30}
									/>
								</Grid>
							) : (
								<DashboardMenus
									selectedDashboard={selectedDashboard}
									selectedNamespace={selectedNamespace}
								/>
							)}
						</Grid>
						<Grid
							size={{ xs: 3 }}
							display="flex"
							justifyContent="flex-end"
							alignItems="center"
							gap={3}
						>
							<Advanced
								permissions={permissions.filter(
									(p) => p.definition.namespace === selectedNamespace?.code,
								)}
								permissionsV2={permissionsV2}
								namespace={selectedNamespace}
							/>
							<RequiresPermission feature="exports">
								<Grid
									container
									className={clsx({
										active: isExportActive,
										inactive: !isExportActive,
									})}
									sx={{
										my: 1,
										py: 0.5,
									}}
								>
									<Grid display="flex">
										<IconButton
											onClick={() => {
												navigate(`/exports?n=${selectedNamespace.code}`);
											}}
											sx={{
												p: 0,
											}}
										>
											<CloudDownloadIcon
												titleAccess="Export"
												sx={{
													color: "white",
													p: 0,
												}}
											/>
										</IconButton>
									</Grid>
								</Grid>
							</RequiresPermission>
							<RequiresPermission
								permission="lookerExplorer:read"
								feature="studio"
								namespaceCode={selectedNamespace?.code}
							>
								<Grid
									container
									className={clsx({
										active: isExploreActive,
										inactive: !isExploreActive,
									})}
									sx={{
										my: 1,
										py: 0.5,
									}}
								>
									<Grid display="flex">
										<IconButton
											onClick={() =>
												navigate(`/explore?n=${selectedNamespace.code ?? ""}`)
											}
											sx={{
												p: 0,
											}}
										>
											<ExploreIcon
												titleAccess="Explore"
												sx={{
													color: "white",
													p: 0,
												}}
											/>
										</IconButton>
									</Grid>
								</Grid>
							</RequiresPermission>
							<IconButton
								onClick={handleOpenUserMenu}
								disabled={!isUserFetched}
							>
								{isUserFetched ? (
									<SpAvatar
										letters={user?.firstName?.charAt(0)}
										backgroundColor={
											sudodAs === user?.altId ? "#77CDAE" : "#65c8e9"
										}
									/>
								) : (
									<Avatar sx={{ width: 28, height: 28 }} />
								)}
							</IconButton>
							<Menu
								sx={{
									mt: ".2em",
								}}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
							>
								<Box sx={{ width: 220, maxWidth: "100%" }}>
									<Grid container sx={{ pb: 1, px: 1 }}>
										<Grid size={{ md: 3 }} display="flex" alignItems={"start"}>
											<SpAvatar
												width={"36px"}
												height={"36px"}
												fontSize={"1.25em"}
												letters={user?.firstName?.charAt(0)}
											/>
										</Grid>
										<Grid size={{ md: 7 }}>
											<Stack>
												<Typography>
													{user?.firstName} {user?.lastName}
												</Typography>
												<Typography>
													{selectedNamespace?.displayName ??
														selectedNamespace?.code}
												</Typography>
												<Link
													to={`/account?n=${selectedNamespace?.code}`}
													style={{ fontSize: 12 }}
													onClick={handleCloseUserMenu}
												>
													Edit Account
												</Link>
											</Stack>
										</Grid>
										<Grid
											size={{ md: 2 }}
											display="flex"
											justifyContent="flex-end"
											alignItems={"center"}
										>
											{availableNamespaces?.length > 1 ? (
												<SwapHorizIcon
													onClick={() => {
														handleCloseUserMenu();
														setShowNamespaceSelection(true);
													}}
													sx={{
														cursor: "pointer",
														"&:hover": {
															backgroundColor: "#f0f1f3",
														},
													}}
												/>
											) : (
												<></>
											)}
										</Grid>
									</Grid>

									<Divider />
									<Grid
										container
										sx={{
											p: 1,
											pt: 1,
											cursor: "pointer",
											"&:hover": {
												backgroundColor: "#f0f1f3",
											},
										}}
										onClick={() => {
											handleCloseUserMenu();
											navigate(`/helpcenter?n=${selectedNamespace?.code}`);
										}}
									>
										<Grid size={{ md: 3 }} display="flex" alignItems={"center"}>
											<HelpCenterIcon />
										</Grid>
										<Grid size={{ md: 9 }} display="flex" alignItems={"center"}>
											Help Center
										</Grid>
									</Grid>
									<Grid
										container
										sx={{
											p: 1,
											pt: 1,
											cursor: "pointer",
											"&:hover": {
												backgroundColor: "#f0f1f3",
											},
										}}
										onClick={handleMenuLogoutClick}
									>
										<Grid size={{ md: 3 }} display="flex" alignItems={"center"}>
											<LogoutIcon />
										</Grid>
										<Grid size={{ md: 9 }} display="flex" alignItems={"center"}>
											Logout
										</Grid>
									</Grid>

									<Dialog open={showConfirmLogout} keepMounted>
										<DialogTitle>Logout?</DialogTitle>
										<DialogContent>
											<DialogContentText>
												You will be redirected to the login page.
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={handleCancelLogout}>Cancel</Button>
											<Button
												onClick={() =>
													logout({
														logoutParams: { returnTo: window.location.origin },
													})
												}
											>
												Logout
											</Button>
										</DialogActions>
									</Dialog>
								</Box>
							</Menu>
						</Grid>
					</Grid>
				</Toolbar>
				<NamespaceDialog
					namespaces={availableNamespaces}
					open={showNamespaceSelection}
					onClose={(value) => {
						setShowNamespaceSelection(false);
					}}
					onSelect={(value) => {
						setShowNamespaceSelection(false);
						setSearchParams({ n: value }, { replace: true });
						window.location.reload();
					}}
				/>
			</AppBar>
			{sudodAs && user ? (
				<AppBar position="static" color="secondary">
					<Grid container sx={{ px: 4, py: 1 }}>
						<Grid size={{ md: 6 }} display="flex" alignItems={"center"}>
							You are sudo'd as {user.firstName} {user.lastName}
						</Grid>
						<Grid size={{ md: 6 }} display="flex" justifyContent={"end"}>
							<Grid
								container
								onClick={handleExitClick}
								sx={{
									cursor: "pointer",
								}}
							>
								<Grid display="flex" alignItems={"center"}>
									<ClearIcon />
								</Grid>
								<Grid display="flex" alignItems={"center"}>
									Exit Sudo
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</AppBar>
			) : (
				<></>
			)}
		</>
	);
}

export default TopBar;
