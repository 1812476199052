import { Namespace } from "@/Models/Namespace";
import * as nodeServer from "../Clients/NodeServer";

interface NamespaceState {
    available: Namespace[]; // adjust type as needed
    isFetching?: boolean;
    isFetched?: boolean;
    error?: any;
}

const initialState: NamespaceState = {
    available: [],
};

let _namespaces: NamespaceState = { ...initialState };

const namespaceSubscribers: Set<() => void> = new Set();

const store = {
    setNamespaces: (namespaces: Partial<NamespaceState>) => {
        _namespaces = { ..._namespaces, ...namespaces };
        for (const cb of namespaceSubscribers) {
            cb();
        }
    },
    getNamespaces: (): NamespaceState => {
        return _namespaces;
    },
    subscribeToNamespaces(callback: () => void) {
        namespaceSubscribers.add(callback);
        return () => namespaceSubscribers.delete(callback);
    },
};

const actions = {
    async fetchNamespaces(token: string) {
        if (_namespaces.isFetching) {
            return;
        }
        store.setNamespaces({
            ..._namespaces,
            isFetching: true,
        });
        try {
            const resp = await nodeServer.fetchNamespaces(token);
            store.setNamespaces({
                ..._namespaces,
                available: resp.data,
                isFetching: false,
                isFetched: true,
            });
        } catch (e) {
            store.setNamespaces({
				..._namespaces,
                available: [],
                error: e,
                isFetching: false,
                isFetched: true,
            });
        }
    },

    resetState: () => {
        store.setNamespaces({ ...initialState });
    },
};

export { store, actions };
