import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Popover from "@mui/material/Popover";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Typeography from "@mui/material/Typography";
import Stack from "@mui/system/Stack";
import { useEffect, useState } from "react";
import {
	useLocation,
	useNavigate,
	useOutletContext,
	useParams,
} from "react-router-dom";
import PageHeader from "../../Components/PageHeader";
import RequiresPermission from "../../Components/RequirePermission";
import SpHeader from "../../Components/SpHeader";
import useDeleteUser from "../../Hooks/useDeleteUser";
import ChangePasswordSection from "./ChangePasswordSection";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import useFetchUser from "./useFetchUser";
import useUpdateUser from "./useUpdateUser";

// https://mui.com/material-ui/react-text-field/#helper-text
const EMPTY_HELPER_TEXT = " ";

//TODO check if namespace being used = owner namespace of user, this should always match
export default () => {
	const { userId } = useParams();
	const { state } = useLocation();
	const navigate = useNavigate();

	const [namespace] = useOutletContext();

	const {
		response: userResponse,
		hasFetchedUser,
		isFetchingUser,
		fetchUser,
		error: fetchUserError,
	} = useFetchUser();

	const {
		isUpdatingUser,
		hasUpdatedUser,
		updateUser,
		error: updateUserError,
	} = useUpdateUser();

	const {
		isDeletingUser,
		hasDeletedUser,
		deleteUser,
		error: deleteUserError,
	} = useDeleteUser();

	const [user, setUser] = useState(null);
	const [firstName, setFirstName] = useState("");
	const [id, setId] = useState("");
	const [lastName, setLastName] = useState("");
	const [existingNamespace, setExistingNamespace] = useState("");
	const [email, setEmail] = useState("");
	const [firstNameHelperText, setFirstNameHelperText] =
		useState(EMPTY_HELPER_TEXT);
	const [firstNameError, setFirstNameError] = useState(false);
	const [lastNameHelperText, setLastNameHelperText] =
		useState(EMPTY_HELPER_TEXT);
	const [lastNameError, setLastNameError] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
	const [copiedPopoverAnchorEl, setCopiedPopoverAnchorEl] = useState(null);
	useEffect(() => {
		if (id) {
			return;
		}
		if (state?.user) {
			setUser(state.user);
			setFirstName(state?.user.firstName);
			setLastName(state?.user.lastName);
			setEmail(state?.user.email);
			setId(state?.user.altId);
			setExistingNamespace(state?.user.namespace);
		} else if (userResponse) {
			setUser(userResponse.data.user);
			setFirstName(userResponse.data.user.firstName);
			setLastName(userResponse.data.user.lastName);
			setEmail(userResponse.data.user.email);
			setId(userResponse.data.user.altId);
			setExistingNamespace(userResponse.data.user.namespace);
		}
	}, [state, id, userResponse]);

	useEffect(() => {
		if (state?.user || hasFetchedUser || isFetchingUser) {
			return;
		}
		fetchUser(userId);
	}, [userId, state?.user, hasFetchedUser, isFetchingUser, fetchUser]);

	useEffect(() => {
		if (hasUpdatedUser || hasDeletedUser) {
			navigate(`/users?n=${namespace.code}`);
		}
	}, [hasUpdatedUser, hasDeletedUser, navigate, namespace]);

	//namespace switching can happen while changing a user, ensure it doesn't mess anything up
	useEffect(() => {
		if (user && user.namespace !== namespace.code) {
			navigate("/");
		}
	}, [user, namespace, navigate]);

	useEffect(() => {
		if (updateUserError || fetchUserError || deleteUserError) {
			setShowSnackbar(true);
			setSnackbarMessage("Something went wrong. Please try again later.");
		}
	}, [updateUserError, fetchUserError, deleteUserError]);

	return (
		<>
			<PageHeader>Account</PageHeader>

			<Grid
				container
				py={6}
				rowSpacing={4}
				columnSpacing={{ xl: 4, xs: 1 }}
				sx={{
					color: "black",
				}}
			>
				<Grid size={{ md: 12, xs: 12 }} px={6}>
					<SpHeader sx={{ pt: 0, height: "1em" }}>User Details</SpHeader>
				</Grid>
				<Grid size={{ md: 1, xs: 1 }} />
				<Grid size={{ md: 5, xs: 10 }}>
					<TextField
						label="First Name"
						value={firstName}
						onChange={(e) => {
							setFirstName(e.target.value);
							if (e.target.value) {
								setFirstNameError(false);
								setFirstNameHelperText(EMPTY_HELPER_TEXT);
							} else {
								setFirstNameHelperText("First name is required");
								setFirstNameError(true);
							}
						}}
						fullWidth
						helperText={firstNameHelperText}
						error={firstNameError}
					/>
				</Grid>
				<Grid size={{ md: 6, xs: 1 }} />
				<Grid size={{ md: 1, xs: 1 }} />
				<Grid size={{ md: 5, xs: 10 }}>
					<TextField
						label="Last Name"
						value={lastName}
						onChange={(e) => {
							setLastName(e.target.value);
							if (e.target.value) {
								setLastNameError(false);
								setLastNameHelperText(EMPTY_HELPER_TEXT);
							} else {
								setLastNameHelperText("Last name is required");
								setLastNameError(true);
							}
						}}
						fullWidth
						helperText={lastNameHelperText}
						error={lastNameError}
					/>
				</Grid>
				<Grid size={{ md: 6, xs: 1 }} />
				<Grid size={{ md: 1, xs: 2 }} />
				<Grid size={{ md: 5, xs: 9 }}>
					<TextField
						label="Email"
						value={email}
						disabled
						fullWidth
						helperText={EMPTY_HELPER_TEXT}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={(e) => {
											navigator.clipboard.writeText(email);
											setCopiedPopoverAnchorEl(e.currentTarget);
											setTimeout(() => {
												setCopiedPopoverAnchorEl(null);
											}, 3000);
										}}
									>
										<ContentCopyIcon
											sx={{
												fontColor: "#65c8e9",
											}}
										/>
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid size={{ md: 6, xs: 1 }} />
				<Grid size={{ md: 1, xs: 2 }} />
				<Grid size={{ md: 5, xs: 9 }}>
					<TextField
						label="ID"
						value={id}
						disabled
						fullWidth
						helperText={EMPTY_HELPER_TEXT}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={(e) => {
											navigator.clipboard.writeText(id);
											setCopiedPopoverAnchorEl(e.currentTarget);
											setTimeout(() => {
												setCopiedPopoverAnchorEl(null);
											}, 3000);
										}}
									>
										<ContentCopyIcon
											sx={{
												fontColor: "#65c8e9",
											}}
										/>
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid size={{ md: 6, xs: 1 }} />
				<Grid size={{ md: 3, xs: 1 }} />
				<Grid size={{ md: 3, xs: 2 }}>
					<Stack direction="row" spacing={4}>
						<LoadingButton
							loading={isDeletingUser}
							variant="outlined"
							fullWidth
							disabled={
								isFetchingUser ||
								firstNameError ||
								lastNameError ||
								isUpdatingUser
							}
							onClick={() => setShowConfirmDeleteDialog(true)}
						>
							Delete
						</LoadingButton>
						<RequiresPermission
							permission="admin:readWrite"
							permissionV2="sailplan:super"
							namespaceCode="sailplan"
						>
							<LoadingButton
								loading={isUpdatingUser}
								variant="outlined"
								fullWidth
								disabled={
									isFetchingUser ||
									firstNameError ||
									lastNameError ||
									isDeletingUser
								}
								onClick={() => {
									window.sessionStorage.setItem("sudodAs", id);
									window.location.assign(
										`${window.location.origin}/?n=${namespace.code}`,
									);
								}}
							>
								Sudo As
							</LoadingButton>
						</RequiresPermission>
						<LoadingButton
							loading={isUpdatingUser}
							variant="contained"
							fullWidth
							disabled={
								isFetchingUser ||
								!firstNameError ||
								lastNameError ||
								isDeletingUser
							}
							onClick={() =>
								updateUser({
									userId,
									firstName,
									lastName,
									namespace: existingNamespace,
								})
							}
						>
							Save
						</LoadingButton>
					</Stack>
				</Grid>
				<Grid size={{ md: 0, xs: 8 }} />
			</Grid>
			<ChangePasswordSection user={user} />
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={showSnackbar}
				autoHideDuration={6000}
				onClose={() => setShowSnackbar(false)}
			>
				<Alert severity={"error"} sx={{ width: "100%" }}>
					{snackbarMessage}
				</Alert>
			</Snackbar>
			<ConfirmDeleteDialog
				open={showConfirmDeleteDialog}
				name={`${firstName} ${lastName}`}
				onCancel={() => {
					setShowConfirmDeleteDialog(false);
				}}
				onContinue={() => {
					deleteUser(id);
					setShowConfirmDeleteDialog(false);
				}}
			/>
			<Popover
				open={Boolean(copiedPopoverAnchorEl)}
				anchorEl={copiedPopoverAnchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				<Typeography sx={{ m: 2 }}>Copied to clipboard!</Typeography>
			</Popover>
		</>
	);
};
