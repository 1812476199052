import { useMemo, useSyncExternalStore } from "react";
import { store as meStore } from "../Stores/Me";
import { RequiresPermissionProps } from "@/Models/Authorization";

export default function RequiresPermission({
	permission,
	permissionV2,
	feature,
	children,
	namespaceCode,
}: RequiresPermissionProps) {

	const { permissions, permissionsV2, user } = useSyncExternalStore(
		meStore.subscribeToMe,
		meStore.getMe,
	);

	const isAuthorized = useMemo<boolean>(
		() => {
			let isAuthorized = false;
			if(permission){
				isAuthorized = !!permissions.find(
					(p) =>
						p.name === permission && p.definition.namespace === namespaceCode,
				)
			} 
			
			if(!isAuthorized && permissionV2) {
				isAuthorized =  !!permissionsV2.find((p) => p === permissionV2);
			}

			if(!isAuthorized && feature && user) {
				isAuthorized =  !!user.features[feature];
			}

			return isAuthorized
		},
		[permission, permissions, namespaceCode, user],
	);

	function render() {
		if (isAuthorized) {
			return <>{children}</>;
		}

		return <></>;
	}

	return render();
}
