import Box from "@mui/material/Box";
import { useMemo, useSyncExternalStore } from "react";
import { Outlet } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { store as meStore } from "../Stores/Me";
import { RequiresPermissionProps } from "@/Models/Authorization";
import { Namespace } from "@/Models/Namespace";


export default function RequiresPermissionContainer({ permission, permissionV2, feature }: RequiresPermissionProps) {
	const { permissions, permissionsV2, user, isFetching } = useSyncExternalStore(
		meStore.subscribeToMe,
		meStore.getMe,
	);

	const [namespace] = useOutletContext<[Namespace]>();

	const isAuthorized = useMemo<boolean>(
		() => {
			let isAuthorized = false;
			if(permission){
				isAuthorized = !!permissions.find(
					(p) =>
						p.name === permission && p.definition.namespace === namespace?.code,
				)
			} 
			
			if(!isAuthorized && permissionV2) {
				isAuthorized =  !!permissionsV2.find((p) => p === `${namespace?.code}:${permissionV2}`);
			}

			if(!isAuthorized && feature && user) {
				isAuthorized =  !!user.features[feature];
			}

			return isAuthorized
		},
		[permission, permissions, namespace]
	);

	function render() {
		if (isAuthorized) {
			return <Outlet context={[namespace]} />;
		}

		if (isFetching) {
			return (
				<Box
					sx={{
						position: "absolute",
						top: "50vh",
						left: "50vw",
						transform: "translate(-50%, -50%)",
					}}
				>
					<Box>
						<BounceLoader color="#44ABDF" size={80} />
					</Box>
				</Box>
			);
		}

		return (
			<Box
				height="100%"
				width="100%"
				alignItems="center"
				display="flex"
				flexDirection="column"
				justifyContent="center"
			>
				<h2>Page Not Found</h2>
				<p>
					Sorry, the page you are looking for does not exist or cannot be
					accessed. If the problem persists, contact your administrator.
				</p>
			</Box>
		);
	}

	return render();
}
