import { Namespace } from "@/Models/Namespace";
import { Permission } from "@/Models/User";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";


interface AdvancedProps {
	permissions: Permission[];
	permissionsV2: string[];
	namespace?: Namespace;
}

export default function ({ permissions, permissionsV2, namespace }: AdvancedProps) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const navigate = useNavigate();

	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const isUserManager = useMemo(() => {
		const oldPermissions = permissions?.some((p) => p.name === "users:readWrite");
		const newPermissions = namespace ? permissionsV2?.some((p) => p === `${namespace.code}:users:readWrite`) : null;
		return oldPermissions || newPermissions;
	}, [permissions, permissionsV2, namespace]);

	const isAuthorized = isUserManager;
	return (
		<Box
			sx={{
				color: isAuthorized ? "#FFFFFF" : "#747D92",
				cursor: isAuthorized ? "pointer" : "cursor",
			}}
			title={isAuthorized ? "" : "Advanced Options... Comming Soon"}
		>
			<Grid container onClick={handleClick}>
				<Grid display="flex">Advanced</Grid>
				<Grid display="flex">
					<ArrowDropDownIcon
						sx={{
							transform: isAuthorized && open ? "scaleY(-1)" : "scaleY(1)",
						}}
					/>
				</Grid>
			</Grid>
			{isAuthorized ? (
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={() => {
						setAnchorEl(null);
					}}
					sx={{
						mt: "1em",
					}}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					<MenuItem
						onClick={() => {
							navigate(`/users?n=${namespace.code}`);
							setAnchorEl(null);
						}}
					>
						User Management
					</MenuItem>
				</Menu>
			) : (
				<></>
			)}
		</Box>
	);
}
