import * as nodeServer from "../Clients/NodeServer";
import { User, Permission } from "../Models/User";
type Callback = () => void;

interface MeState {
    user: User | null;
    permissions: Permission[];
	permissionsV2: string[];
    error: any;
    sudodAs: string | null;
    isFetching: boolean;
    isFetched: boolean;
    isUpdating: boolean;
    isUpdated: boolean;
    isBusting?: boolean;
}

interface ResetPasswordState {
    error: any;
    isFetching: boolean;
    isFetched: boolean;
}

const meInitialState: MeState = {
    user: null,
    permissions: [],
	permissionsV2: [],
    error: null,
    sudodAs: window.sessionStorage.getItem("sudodAs"),
    isFetching: false,
    isFetched: false,
    isUpdating: false,
    isUpdated: false,
};

let _me: MeState = { ...meInitialState };

const resetMyPasswordInitialState: ResetPasswordState = {
    error: null,
    isFetching: false,
    isFetched: false,
};

let _resetMyPassword: ResetPasswordState = {
    ...resetMyPasswordInitialState,
};

const meSubscribers = new Set<Callback>();
const resetMyPasswordSubscribers = new Set<Callback>();

const store = {
    getMe: (): MeState => {
        return _me;
    },
    setMe: async (me: MeState) => {
        _me = me;
        for (const cb of meSubscribers) {
            cb();
        }
    },
    setResetMyPassword: (resetMyPassword: ResetPasswordState) => {
        _resetMyPassword = resetMyPassword;
        for (const cb of resetMyPasswordSubscribers) {
            cb();
        }
    },
    getResetMyPassword: (): ResetPasswordState => {
        return _resetMyPassword;
    },
    subscribeToMe(callback: Callback) {
        meSubscribers.add(callback);
        return () => meSubscribers.delete(callback);
    },
    subscribeToResetMyPassword(callback: Callback) {
        resetMyPasswordSubscribers.add(callback);
        return () => resetMyPasswordSubscribers.delete(callback);
    },
};

const actions = {
    async fetchMe(token: string) {
        if (_me.isFetching) {
            return;
        }
        store.setMe({
            ..._me,
            isFetching: true,
        });
        try {
            const resp = await nodeServer.fetchMe(token);
            store.setMe({
                ..._me,
                ...resp.data,
                isFetching: false,
                isFetched: true,
            });
        } catch (e) {
            store.setMe({
                ..._me,
                error: e,
                isFetching: false,
                isFetched: true,
            });
        }
    },
    async bustCookies(token: string){
        store.setMe({
            ..._me,
            isBusting: true,
        });
        try {
            await nodeServer.bustCookies(token);
        } catch (e) {
            console.error(e);
        } finally {
            store.setMe({
                ..._me,
                isBusting: false,
            });
        }
    },
    async updateMe(token: string, firstName: string, lastName: string, namespace: string) {
        if (_me.isUpdating) {
            return;
        }
        store.setMe({
            ..._me,
            isUpdating: true,
        });
        try {
            const resp = await nodeServer.updateMe(token, {
                firstName,
                lastName,
                namespace,
            });
            store.setMe({
                ..._me,
                ...resp.data,
                isUpdating: false,
                isUpdated: true,
            });
        } catch (e) {
            store.setMe({
                ..._me,
                error: e,
                isUpdating: false,
                isUpdated: false,
            });
        }
    },
    async resetMyPassword(token: string) {
        if (_resetMyPassword.isFetching) {
            return;
        }
        store.setResetMyPassword({
            ..._resetMyPassword,
            isFetching: true,
        });
        try {
            await nodeServer.requestMyPasswordReset(token);
            store.setResetMyPassword({
                ..._resetMyPassword,
                isFetching: false,
                isFetched: true,
            });
        } catch (e: any) {
            store.setResetMyPassword({
                ..._resetMyPassword,
                error: e,
                isFetching: false,
                isFetched: true,
            });
        }
    },
    resetState() {
        store.setMe({ ...meInitialState });
        store.setResetMyPassword({ ...resetMyPasswordInitialState });
    },
};

export { store, actions };
