import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NONE = "";
function useCachedNamespace() {
	const navigate = useNavigate();
	useEffect(() => {
		function onStorage(event) {
			if (event.key === "selectedNamespace") {
				navigate(`/?n=${event.newValue}`);
			}
		}

		window.addEventListener("storage", onStorage);

		return () => {
			window.removeEventListener("storage", onStorage);
		};
	});

	const setCachedNamespace = useCallback(
		(n) => {
			localStorage.setItem("selectedNamespace", n ?? NONE);
		},
		[]
	);
	return [
		localStorage.getItem("selectedNamespace") ?? NONE,
		setCachedNamespace,
	];
}

export default useCachedNamespace;
