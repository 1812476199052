import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import { blue } from "@mui/material/colors";
import PropTypes from "prop-types";
import { useState } from "react";

function NamespaceDialog(props) {
	const { onClose, onSelect, open, namespaces } = props;
	const [searchTerm, setSearchTerm] = useState("");

	// Filter namespaces by display name, case-insensitive
	const filteredNamespaces = namespaces.filter((ns) =>
		ns.displayName.toLowerCase().includes(searchTerm.toLowerCase()),
	);

	return (
		<Dialog
			open={open}
			scroll="paper"
			onClose={onClose}
			PaperProps={{ style: { maxHeight: 340 } }}
		>
			<DialogTitle>
				<TextField
					autoFocus
					margin="dense"
					label="Select Namespace"
					type="text"
					fullWidth
					variant="outlined"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
				/>
			</DialogTitle>
			<DialogContent dividers>
				<List sx={{ p: 0 }}>
					{filteredNamespaces.map((ns) => (
						<ListItem disableGutters key={ns.code} sx={{ p: 0 }}>
							<ListItemButton onClick={() => onSelect(ns.code)}>
								<ListItemAvatar>
									<Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
										<SpaceDashboardIcon />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary={ns.displayName} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} variant="text" maxWidth="sm" fullWidth>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}

NamespaceDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	namespaces: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default NamespaceDialog;
