import { useAuth0 } from "@auth0/auth0-react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import { useEffect, useState, useSyncExternalStore } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import ExportTracker from "../Components/ExportTacker";
import NamespaceDialog from "../Components/NamespaceDialog";
import TopBar from "../Components/TopBar";
import { handleGetAccessToken } from "../Helpers/auth";
import useCachedNamespace from "../Hooks/useCachedNamespace";
import { actions as meActions, store as meStore } from "../Stores/Me";
import {
	actions as namespaceActions,
	store as namespaceStore,
} from "../Stores/Namespace";

// Set namespace if it is not in the query params
// Fetch user and permissions
// Initialize all available namespaces
function BaseContainer() {
	const [cachedNamespace, setCachedNamespace] = useCachedNamespace();
	const [showNamespaceSelection, setShowNamespaceSelection] = useState(false);
	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
	const [showSnackbar, setShowSnackbar] = useState(false);
	const { available: availableNamespaces } = useSyncExternalStore(
		namespaceStore.subscribeToNamespaces,
		namespaceStore.getNamespaces,
	);

	const { isFetched: isUserFetched, isFetching: isFetchingUser } =
		useSyncExternalStore(meStore.subscribeToMe, meStore.getMe);

	const [searchParams, setSearchParams] = useSearchParams();
	const namespaceQueryParams = searchParams.get("n");

	const namespace = availableNamespaces.find(
		(a) => namespaceQueryParams === a.code,
	);
	// Fetch user and permissions
	useEffect(() => {
		if (!isFetchingUser && !isUserFetched) {
			handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
				(token) => {
					meActions.fetchMe(token);
					namespaceActions.fetchNamespaces(token);
					meActions.bustCookies(token);
				},
			);
		}
	}, [
		isUserFetched,
		isFetchingUser,
		getAccessTokenSilently,
		loginWithRedirect,
	]);

	//set n query param
	useEffect(() => {
		const namespace = availableNamespaces.find(
			(a) => namespaceQueryParams === a.code,
		);
		if (namespaceQueryParams && !namespace && availableNamespaces.length) {
			if (availableNamespaces.length === 1) {
				setSearchParams(
					{ n: availableNamespaces.at(0).code },
					{ replace: true },
				);
			} else {
				setShowNamespaceSelection(true);
			}
			return;
			// biome-ignore lint/style/noUselessElse: <explanation>
		} else if (
			namespaceQueryParams &&
			namespaceQueryParams === cachedNamespace
		) {
			setShowNamespaceSelection(false);
			return;
		}

		//Case where a namespace is selected in the query param but does not exist in the available namespaces
		if (namespaceQueryParams && namespaceQueryParams !== cachedNamespace) {
			setCachedNamespace(namespaceQueryParams);
		} else if (cachedNamespace && namespaceQueryParams !== cachedNamespace) {
			setSearchParams({ n: cachedNamespace }, { replace: true });
		} else if (!cachedNamespace && availableNamespaces.length === 1) {
			setSearchParams({ n: availableNamespaces.at(0).code }, { replace: true });
		} else if (
			!cachedNamespace &&
			!namespaceQueryParams &&
			availableNamespaces.length > 1
		) {
			setShowNamespaceSelection(true);
			setShowSnackbar(true);
		}
	}, [
		availableNamespaces,
		cachedNamespace,
		namespaceQueryParams,
		setSearchParams,
		setCachedNamespace,
	]);

	return (
		<>
			<Box display="flex" flexDirection="column" height="100%">
				<TopBar selectedNamespace={namespace} />
				<Outlet context={[namespace]} />

				<NamespaceDialog
					namespaces={availableNamespaces}
					open={showNamespaceSelection}
					onClose={(value) => {
						setShowNamespaceSelection(false);
					}}
					onSelect={(value) => {
						setSearchParams({ n: value }, { replace: true });
						setShowSnackbar(false);
					}}
				/>
				<ExportTracker />
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={showSnackbar}
				autoHideDuration={6000}
				onClose={() => {
					setShowSnackbar(false);
				}}
			>
				<Alert severity={"warning"} sx={{ width: "100%" }}>
					{"No namespace selected. Please select a namespace."}
				</Alert>
			</Snackbar>
		</>
	);
}

export default BaseContainer;
