import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useSyncExternalStore } from "react";
import { handleGetAccessToken } from "../../Helpers/auth";
import {
	actions as dashboardActions,
	store as dashboardStore,
} from "../../Stores/Dashboard";

import LookerEmbed from "./components/LookerEmbed";
import "./styles.css";
import Box from "@mui/material/Box";
import { debounce } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import useSelectedDashboard from "../../Hooks/useSelectedDashboard";

// TODO error component
export default () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const {
		error: fetchDashboardsError,
		isFetching: fetchingDashboards,
		isFetched: hasFetchedDashboards,
	} = useSyncExternalStore(
		dashboardStore.subscribeToDashboards,
		dashboardStore.getDashboards,
	);

	const [selectedNamespace] = useOutletContext();
	const selectedDashboard = useSelectedDashboard(selectedNamespace?.code);

	useEffect(() => {
		if (selectedDashboard?.displayName) {
			document.title = `SailPlan - ${selectedDashboard.displayName}`;
		}
		return () => {
			document.title = "SailPlan";
		};
	}, [selectedDashboard]);
	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	//handle default dashboard selection case
	useEffect(() => {
		if (!hasFetchedDashboards || !selectedNamespace) {
			return;
		}

		const defaultDashboard = dashboardStore.getDefaultDashboard(
			selectedNamespace.code,
		);

		if (!selectedDashboard && defaultDashboard) {
			const filters = JSON.stringify(
				dashboardStore.getFilters(
					selectedNamespace.code,
					defaultDashboard.slug,
				),
			);
			navigate(
				`/dashboard/${defaultDashboard.slug}?filters=${filters}&n=${selectedNamespace.code}`,
				{ replace: true },
			);
		}
	}, [selectedDashboard, selectedNamespace, navigate, hasFetchedDashboards]);

	//initialize dashboard filters params
	useEffect(() => {
		if (!selectedDashboard || !selectedNamespace) {
			return;
		}
		const searchParamsFilters = searchParams.get("filters");
		if (!searchParamsFilters) {
			setSearchParams(
				{
					filters: JSON.stringify(
						dashboardStore.getFilters(
							selectedNamespace.code,
							selectedDashboard.slug,
						),
					),
					n: selectedNamespace.code,
				},
				{
					replace: true,
				},
			);
		}
	}, [selectedNamespace, selectedDashboard, searchParams, setSearchParams]);

	//fetch dashboard if not loaded
	useEffect(() => {
		if (hasFetchedDashboards || fetchingDashboards) {
			return;
		}

		handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
			(token) => {
				dashboardActions.fetchDashboards(token);
			},
		);
	}, [
		getAccessTokenSilently,
		loginWithRedirect,
		hasFetchedDashboards,
		fetchingDashboards,
	]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const debouncedSetParams = useCallback(debounce(setSearchParams, 1000), [
		setSearchParams,
	]);

	const getBody = useCallback(() => {
		if (fetchDashboardsError) {
			navigate("/404", { replace: true });
		}
		if (selectedDashboard) {
			const filters = JSON.parse(searchParams.get("filters"));
			return filters ? (
				<LookerEmbed
					showLoading={!hasFetchedDashboards || fetchingDashboards}
					lookerId={selectedDashboard?.lookerId}
					filters={filters ?? {}}
					onFilterChange={(event) => {
						debouncedSetParams(
							{
								filters: JSON.stringify(event.dashboard.dashboard_filters),
								n: selectedNamespace.code,
							},
							{
								replace: true,
							},
						);
						dashboardActions.setFilter(
							selectedNamespace.code,
							selectedDashboard.slug,
							event.dashboard.dashboard_filters,
						);
					}}
				/>
			) : (
				<></>
			);
		}
		if (hasFetchedDashboards && !selectedDashboard) {
			return (
				<Box
					height="100%"
					width="100%"
					alignItems="center"
					gap={2}
					p={2}
					display="flex"
					flexDirection="column"
					justifyContent="center"
					textAlign="center"
				>
					<h2>Nothing to see here.</h2>
					<p>
						Sorry, no dashboards have been found under your account. Please
						contact your administrator.
					</p>
				</Box>
			);
		}
		return <></>;
	}, [
		fetchDashboardsError,
		fetchingDashboards,
		selectedDashboard,
		hasFetchedDashboards,
		debouncedSetParams,
		searchParams,
		navigate,
		selectedNamespace,
	]);

	return (
		<Box display="flex" flexDirection="column" height="100%">
			<Box flexGrow={1} overflow="hidden" height="100%">
				{getBody()}
			</Box>
		</Box>
	);
};
