import { useAuth0 } from "@auth0/auth0-react";
import { LoadingButton } from "@mui/lab";
import {
	Alert,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Snackbar,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useCallback, useEffect, useState, useSyncExternalStore } from "react";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import PageHeader from "../../Components/PageHeader";
import SpHeader from "../../Components/SpHeader";
import { handleGetAccessToken } from "../../Helpers/auth";
import { actions as exportActions } from "../../Stores/Export";
import {
	actions as vesselActions,
	store as vesselsStore,
} from "../../Stores/Vessels";
import useStartExport from "./useStartExport";

function getEndDateError(start, end) {
	if (!end) {
		return "Please select an end date.";
	}

	if (start && end < start) {
		return "End date must be after start date.";
	}

	if (start && end && end.diff(start, "month") > 12) {
		return "Dates cannot be more than a year apart";
	}

	return EMPTY_HELPER_TEXT;
}

function getStartDateError(start, end) {
	if (!start) {
		return "Please select both a start date.";
	}

	if (end && start > end) {
		return "Start date must be before end date.";
	}

	if (end && start && end.diff(start, "month") > 12) {
		return "Dates cannot be more than a year apart";
	}

	return EMPTY_HELPER_TEXT;
}

// https://mui.com/material-ui/react-text-field/#helper-text
const EMPTY_HELPER_TEXT = " ";

export default () => {
	const navigate = useNavigate();

	const [namespace] = useOutletContext();
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [startDateError, setStartDateError] = useState(EMPTY_HELPER_TEXT);
	const [endDateError, setEndDateError] = useState(EMPTY_HELPER_TEXT);
	const [selectedVesselName, setSelectedVesselName] = useState("");
	const [selectedVessel, setSelectedVessel] = useState("");

	const [showSnackbar, setShowSnackbar] = useState(false);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const {
		isFetchingVessels,
		hasFetchedVessels,
		vessels,
		error: fetchVesselsError,
	} = useSyncExternalStore(
		vesselsStore.subscribeToVessels,
		vesselsStore.getVessels,
	);

	const {
		isStartingExport,
		hasStartedExport,
		startExport,
		error: startExportError,
	} = useStartExport();

	const onChangeStartDate = (date) => {
		setStartDate(date);
		setStartDateError(getStartDateError(date, endDate));
		setEndDateError(getEndDateError(date, endDate));
	};

	const onChangeEndDate = (date) => {
		setEndDate(date);
		setStartDateError(getStartDateError(startDate, date));
		setEndDateError(getEndDateError(startDate, date));
	};

	useEffect(() => {
		if (!hasFetchedVessels && !isFetchingVessels) {
			handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
				(token) => {
					vesselActions.fetchVessels(token, namespace.code);
				},
			);
		}
	}, [
		hasFetchedVessels,
		isFetchingVessels,
		namespace,
		getAccessTokenSilently,
		loginWithRedirect,
	]);

	useEffect(() => {
		if (fetchVesselsError || startExportError) {
			setShowSnackbar(true);
		}
	}, [fetchVesselsError, startExportError]);

	useEffect(() => {
		if (hasStartedExport) {
			handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
				(token) => {
					exportActions.fetchInProgressExports(token); //triggers the ExportTracker component
				},
			);
			navigate(`/exports?n=${namespace.code}`);
		}
	}, [
		hasStartedExport,
		namespace,
		navigate,
		getAccessTokenSilently,
		loginWithRedirect,
	]);

	const getVesselSensorId = useCallback((vessel) => {
		const id = vessel.sensorSchema.find(
			(sensor) => sensor.name === "virt",
		)?._id;

		if (id) {
			return id;
		}
		setShowSnackbar(true);
	}, []);

	const SelectVessel = ({ children, isLoading }) => {
		if (isLoading) {
			return (
				<FormControl>
					<Select
						disabled
						renderValue={() => "Loading Vessels..."}
						defaultValue="Loading..."
					>
						{children}
					</Select>
					<FormHelperText> </FormHelperText>
				</FormControl>
			);
		}

		return (
			<FormControl error={!children.length}>
				<InputLabel id="selected-vessel">Vessel</InputLabel>
				<Select
					labelId="selected-vessel"
					id="selected-vessel"
					value={selectedVesselName}
					label="Vessel"
					onChange={(event) => {
						setSelectedVesselName(event.target.value);
						setSelectedVessel(
							vessels.find((v) => v._id === event.target.value),
						);
					}}
				>
					{children}
				</Select>

				<FormHelperText
					sx={{
						visibility: children.length ? "hidden" : "visible",
					}}
				>
					No vessels found in this namespace.
				</FormHelperText>
			</FormControl>
		);
	};
	return (
		<>
			<PageHeader>New Export</PageHeader>
			<Grid
				container
				py={6}
				rowSpacing={4}
				columnSpacing={{ xl: 4, xs: 1 }}
				sx={{
					color: "black",
				}}
			>
				<Grid size={{ md: 12, xs: 12 }} px={6}>
					<SpHeader sx={{ pt: 0, height: "1em" }}>Export Details</SpHeader>
				</Grid>
				<Grid size={{ md: 1, xs: 1 }} />
				<Grid size={{ md: 5, xs: 10 }}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label="Start Date"
							value={startDate}
							slotProps={{
								textField: {
									helperText: startDateError,
									fullWidth: true,
									error: startDateError !== EMPTY_HELPER_TEXT,
								},
							}}
							onChange={onChangeStartDate}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid size={{ md: 6, xs: 1 }} />
				<Grid size={{ md: 1, xs: 1 }} />
				<Grid size={{ md: 5, xs: 10 }}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label="End Date"
							value={endDate}
							slotProps={{
								textField: {
									helperText: endDateError,
									fullWidth: true,
									error: endDateError !== EMPTY_HELPER_TEXT,
								},
							}}
							onChange={onChangeEndDate}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid size={{ md: 6, xs: 1 }} />
				<Grid size={{ md: 1, xs: 1 }} />
				<Grid size={{ md: 5, xs: 10 }}>
					<FormControl fullWidth>
						<SelectVessel isLoading={!hasFetchedVessels || isFetchingVessels}>
							{vessels.map((v) => (
								<MenuItem key={v._id} value={v._id}>
									{v.name}
								</MenuItem>
							))}
						</SelectVessel>
					</FormControl>
					<FormHelperText>
						Note: Exporting data can take a while. You will be notified once
						your download is ready.
					</FormHelperText>
				</Grid>
				<Grid size={{ md: 6, xs: 1 }} />
				<Grid size={{ md: 1, xs: 1 }} />
				<Grid size={{ md: 1, xs: 10 }}>
					<LoadingButton
						variant="contained"
						loading={isStartingExport}
						onClick={() => {
							Notification.requestPermission().then((result) =>
								startExport({
									sensorId: getVesselSensorId(selectedVessel),
									start: startDate,
									end: endDate,
									namespace: namespace.code,
								}),
							);
						}}
						disabled={
							startDateError !== EMPTY_HELPER_TEXT ||
							endDateError !== EMPTY_HELPER_TEXT ||
							!selectedVesselName ||
							isStartingExport ||
							hasStartedExport
						}
						fullWidth
					>
						Export
					</LoadingButton>
				</Grid>
				<Grid size={{ md: 1, xs: 10 }}>
					<LoadingButton
						variant="outlined"
						loading={false}
						onClick={() => {
							navigate("/exports");
						}}
						fullWidth
					>
						Cancel
					</LoadingButton>
				</Grid>
				<Grid size={{ md: 9, xs: 1 }} />
			</Grid>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={showSnackbar}
				autoHideDuration={6000}
				onClose={() => {
					setShowSnackbar(false);
				}}
			>
				<Alert severity={"error"} sx={{ width: "100%" }}>
					Something went wrong. Please try again later.
				</Alert>
			</Snackbar>
		</>
	);
};
