import * as nodeServer from "../Clients/NodeServer";
import { User } from "../Models/User";

type Callback = () => void;

interface UsersLastParams {
    namespace: string;
    pageSize: number;
    query: string;
}

export interface UsersState {
    users: User[];
    next: string | null;
    error: any;
    lastParams: UsersLastParams | Record<string, never>;
    isFetching: boolean;
    isFetched: boolean;
    isCreating: boolean;
    isCreated: boolean;
    isFetchingNext?: boolean;
    createError?: any;
}

const initialState: UsersState = {
    users: [],
    next: null,
    error: null,
    lastParams: {},
    isFetching: false,
    isFetched: false,
    isCreating: false,
    isCreated: false,
};

let _users: UsersState = { ...initialState };

const usersSubscribers: Set<Callback> = new Set();

const store = {
    getUsers: (): UsersState => {
        return _users;
    },
    setUsers: (users: Partial<UsersState>) => {
        _users = { ..._users, ...users };
        for (const cb of usersSubscribers) {
            cb();
        }
    },
    subscribeToUsers(callback: Callback) {
        usersSubscribers.add(callback);
        return () => usersSubscribers.delete(callback);
    },
};

const actions = {
    async fetchUsers(
        token: string,
        { namespaceCode, pageSize, query }: { namespaceCode: string; pageSize: number; query: string }
    ) {
        if (_users.isFetching) {
            return;
        }
        store.setUsers({
            lastParams: { namespace: namespaceCode, pageSize, query },
            isFetching: true,
        });
        try {
            const resp = await nodeServer.getUsers(token, {
                namespace: namespaceCode,
                pageSize,
                query,
            });
            store.setUsers({
                next: resp.data.next,
                users: resp.data.users,
                isFetching: false,
                isFetched: true,
            });
        } catch (e) {
            console.error(e);
            store.setUsers({
                error: e,
                isFetching: false,
                isFetched: true,
            });
        }
    },
    async fetchNext(token: string) {
        if (_users.isFetching || !_users.next) {
            return;
        }
        store.setUsers({
            isFetchingNext: true,
        });
        try {
            // Type assertion since lastParams may either be UsersLastParams or an empty object.
            const params = _users.lastParams as UsersLastParams;
            const resp = await nodeServer.getUsers(token, {
                namespace: params.namespace,
                pageSize: params.pageSize,
                nextToken: _users.next,
                query: params.query,
            });
            store.setUsers({
                next: resp.data.next,
                users: _users.users.concat(resp.data.users),
                isFetchingNext: false,
            });
        } catch (e) {
            console.error(e);
            store.setUsers({
                error: e,
                isFetchingNext: false,
            });
        }
    },
    async createUser(
        token: string,
        {
            firstName,
            lastName,
            email,
            namespace,
            password,
        }: { firstName: string; lastName: string; email: string; namespace: string; password: string }
    ) {
        if (_users.isCreating) {
            return;
        }
        store.setUsers({
            isCreating: true,
            createError: null,
        });
        try {
            const resp = await nodeServer.createUser(
                token,
                {
                    firstName,
                    lastName,
                    email,
                    namespace,
                    password,
                },
                {
                    skipPasswordEmail: !!password,
                },
            );
            store.setUsers({
                next: resp.data.next,
                users: resp.data.users,
                isCreating: false,
                isCreated: true,
            });
        } catch (e: any) {
            console.error(e.response?.data || e);
            store.setUsers({
                error: e,
                isCreating: false,
                isCreated: false,
                createError: e.response?.data,
            });
        }
    },
    resetState() {
        store.setUsers({ ...initialState });
    },
    resetCreateUserState() {
        store.setUsers({
            isCreating: false,
            createError: null,
        });
    },
};

export { store, actions };
