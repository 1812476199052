import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AccountCircle from "@mui/icons-material/Search";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { debounce, throttle } from "lodash";
import { useCallback, useEffect, useState, useSyncExternalStore } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import PageHeader from "../../Components/PageHeader";
import RequiresPermission from "../../Components/RequirePermission";
import SpAvatar from "../../Components/SpAvatar";
import { handleGetAccessToken } from "../../Helpers/auth";
import useDeleteUser from "../../Hooks/useDeleteUser";
import {
	actions as usersActions,
	store as usersStore,
} from "../../Stores/Users";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";

function getInitials(user) {
	return `${user.firstName?.charAt(0) ?? ""}${user.lastName.charAt(0) ?? ""}`;
}

export default () => {
	const navigate = useNavigate();
	const [userQuery, setUserQuery] = useState("");
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedUser, setSelectedUser] = useState(null);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarMessageSeverity, setSnackbarMessageSeverity] = useState("");
	const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
	const {
		isDeletingUser,
		hasDeletedUser,
		deletedUserIds,
		deletingUserId,
		error: deleteUserError,
		deleteUser,
	} = useDeleteUser();
	const { ref, inView } = useInView({
		threshold: 0,
	});

	const open = Boolean(anchorEl);

	const handleOptionsClick = (event, user) => {
		setAnchorEl(event.currentTarget);
		setSelectedUser(user);
	};

	const handleOptionsClose = () => {
		setAnchorEl(null);
		setSelectedUser(null);
	};

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const [namespace] = useOutletContext();

	const {
		users,
		next,
		lastParams,
		isFetching: isFetchingUsers,
		isFetched: hasFetchedUsers,
		error: fetchUsersError,
	} = useSyncExternalStore(usersStore.subscribeToUsers, usersStore.getUsers);

	// biome-ignore lint/correctness/useExhaustiveDependencies: this is a false positive biome err?
	const debounceFetchUsers = useCallback(
		debounce(
			(query) =>
				handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
					(token) => {
						usersActions.fetchUsers(token, {
							namespaceCode: namespace.code,
							query,
						});
					},
				),
			500,
			{
				trailing: true,
			},
		),
		[namespace],
	);

	useEffect(() => {
		if (hasDeletedUser && !deleteUserError) {
			setSnackbarMessageSeverity("success");
			setSnackbarMessage("User deleted successfully.");
			setShowSnackbar(true);
		}
	}, [hasDeletedUser, deleteUserError]);

	useEffect(() => {
		if (deleteUserError || fetchUsersError) {
			setSnackbarMessageSeverity("error");
			setSnackbarMessage("Something went wrong. Please try again later.");
			setShowSnackbar(true);
		}
	}, [deleteUserError, fetchUsersError]);

	//infinite scroll and fetch
	useEffect(() => {
		if (inView && !isFetchingUsers && hasFetchedUsers) {
			handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
				(token) => {
					usersActions.fetchNext(token);
				},
			);
		}
	}, [
		getAccessTokenSilently,
		loginWithRedirect,
		inView,
		isFetchingUsers,
		hasFetchedUsers,
	]);

	//reset users in list
	useEffect(() => {
		if (
			lastParams?.namespace &&
			lastParams.namespace !== namespace.code &&
			hasFetchedUsers
		) {
			usersActions.resetState();
		}
	}, [namespace, lastParams, hasFetchedUsers]);

	//initial fetch
	useEffect(() => {
		if (
			isFetchingUsers ||
			!namespace ||
			lastParams?.namespace === namespace.code
		) {
			return;
		}
		handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
			(token) => {
				usersActions.fetchUsers(token, { namespaceCode: namespace.code });
			},
		);
	}, [
		getAccessTokenSilently,
		loginWithRedirect,
		isFetchingUsers,
		namespace,
		lastParams,
	]);

	const onSearchUserKeyPress = (event) => {
		setUserQuery(event.target.value);
		if (event.key === "Enter") {
			debounceFetchUsers(event.target.value);
		}
	};

	const isDeletingSelectedUser = (userId) => {
		return isDeletingUser && deletingUserId === userId;
	};

	useEffect(() => {
		const scrollRestore = window.history.state?.scrollRestore;
		if (scrollRestore) {
			window.scrollTo(0, scrollRestore);
		}
	}, []);

	const usersNoDeleted = users?.filter(
		(u) => !deletedUserIds.includes(u.altId),
	);

	//throttle to make sure it's called atleast once every 1 sec
	useEffect(() => {
		const listen = throttle(() => {
			const currentState = window.history.state ?? {};
			window.history.replaceState(
				{
					...currentState,
					scrollRestore: window.scrollY,
				},
				"",
			);
		}, 1000);
		window.addEventListener("scroll", listen);
		return () => {
			window.removeEventListener("scroll", listen);
		};
	}, []);

	useEffect(() => {
		if (userQuery) {
			debounceFetchUsers(userQuery);
		}
	}, [userQuery, debounceFetchUsers]);

	return (
		<>
			<PageHeader>User Management</PageHeader>

			<Grid container p={4}>
				<Grid size={{ xs: 4 }}>
					<TextField
						InputProps={{
							startAdornment: (
								<InputAdornment
									position="start"
									sx={{
										cursor: "pointer",
									}}
									onClick={() => debounceFetchUsers(userQuery)}
								>
									<AccountCircle
										sx={{
											fontColor: "#65c8e9",
										}}
									/>
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment
									position="end"
									sx={{
										cursor: "pointer",
									}}
									onClick={() => {
										setUserQuery("");
										debounceFetchUsers("");
									}}
								>
									<CloseIcon
										sx={{
											fontColor: "#65c8e9",
										}}
									/>
								</InputAdornment>
							),
						}}
						label="User Search"
						value={userQuery}
						onChange={onSearchUserKeyPress}
						fullWidth
					/>
				</Grid>
				<Grid size={{ xs: 7 }} />

				<Grid
					size={{ xs: 1 }}
					display="flex"
					alignItems="center"
					justifyContent={"center"}
				>
					<Button
						endIcon={<PersonAddAltIcon />}
						onClick={() => navigate(`/adduser?n=${namespace.code}`)}
					>
						Add User
					</Button>
				</Grid>
			</Grid>
			{isFetchingUsers || !hasFetchedUsers ? (
				<Box
					sx={{
						position: "absolute",
						top: "50vh",
						left: "50vw",
						transform: "translate(-50%, -50%)",
					}}
				>
					<BounceLoader color="#44ABDF" size={80} />
				</Box>
			) : usersNoDeleted?.length ? (
				<Stack spacing={2} sx={{ px: 4 }} divider={<Divider flexItem />}>
					<Grid
						container
						sx={{
							color: "#03143d",
							fontWeight: 600,
							fontSize: "1em",
						}}
					>
						<Grid size={{ xs: 4 }}>User</Grid>
						<Grid size={{ xs: 4 }} display="flex" alignItems="center">
							Email
						</Grid>
						<Grid size={{ xs: 3 }} display="flex" alignItems="center">
							Roles
						</Grid>
						<Grid />
					</Grid>
					{usersNoDeleted.map((user) => (
						<Grid key={user._id} container>
							<Grid size={{ xs: 4 }}>
								<Stack direction="row">
									<SpAvatar
										letters={getInitials(user)}
										width={37}
										height={37}
										onClick={() => {
											navigate(`/edituser/${user.altId}?n=${namespace.code}`, {
												state: { user },
											});
										}}
										sx={{ cursor: "pointer" }}
									/>
									<Stack sx={{ ml: 1 }}>
										<Box>{`${user.firstName} ${user.lastName}`}</Box>
										<Box
											sx={{ color: "#aaa", fontSize: ".75em" }}
										>{`${user.altId}`}</Box>
									</Stack>
								</Stack>
							</Grid>
							<Grid size={{ xs: 4 }} display="flex" alignItems="center">
								{user.email}
							</Grid>
							<Grid size={{ xs: 3 }} display="flex" alignItems="center">
								{user.roles
									.filter((r) => {
										return r.namespace === namespace.code;
									})
									.map((r) => r.name)
									.join(", ")}
							</Grid>
							<Grid
								size={{ xs: 1 }}
								display="flex"
								alignItems="center"
								justifyContent={"center"}
							>
								{isDeletingSelectedUser(user.altId) ? (
									<BounceLoader color="#44ABDF" size={37} />
								) : (
									<IconButton
										onClick={(event) => {
											handleOptionsClick(event, user);
										}}
									>
										<MoreVertIcon />
									</IconButton>
								)}
							</Grid>
						</Grid>
					))}
					{next ? (
						<div ref={ref}>
							<Grid container sx={{ pb: 2 }}>
								<Grid display="flex">
									<BounceLoader color="#44ABDF" size={37} />
								</Grid>
								<Grid sx={{ ml: 1 }} display="flex" alignItems="center">
									Loading...
								</Grid>
							</Grid>
						</div>
					) : (
						<></>
					)}
				</Stack>
			) : (
				<Box
					height="50%"
					width="100%"
					alignItems="center"
					display="flex"
					justifyContent="center"
				>
					<h3>No users found</h3>
				</Box>
			)}
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleOptionsClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<MenuItem
					onClick={() => {
						navigate(`/edituser/${selectedUser.altId}?n=${namespace.code}`, {
							state: { user: selectedUser },
						});
					}}
				>
					Edit
				</MenuItem>
				<RequiresPermission
					permission="admin:readWrite"
					permissionV2="sailplan:super"
					namespaceCode="sailplan"
				>
					<MenuItem
						onClick={() => {
							window.sessionStorage.setItem("sudodAs", selectedUser.altId);
							window.location.assign(
								`${window.location.origin}/?n=${namespace.code}`,
							);
						}}
					>
						Sudo As
					</MenuItem>
				</RequiresPermission>
				<MenuItem onClick={() => setShowConfirmDeleteDialog(true)}>
					Delete
				</MenuItem>
			</Menu>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={showSnackbar}
				autoHideDuration={6000}
				onClose={() => {
					setShowSnackbar(false);
				}}
			>
				<Alert severity={snackbarMessageSeverity} sx={{ width: "100%" }}>
					{snackbarMessage}
				</Alert>
			</Snackbar>
			<ConfirmDeleteDialog
				open={showConfirmDeleteDialog}
				name={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
				onCancel={() => {
					setShowConfirmDeleteDialog(false);
					handleOptionsClose();
				}}
				onContinue={() => {
					deleteUser(selectedUser.altId);
					setShowConfirmDeleteDialog(false);
					handleOptionsClose();
				}}
			/>
		</>
	);
};
