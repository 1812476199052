import { useAuth0 } from "@auth0/auth0-react";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DownloadingIcon from "@mui/icons-material/Downloading";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { useEffect, useState, useSyncExternalStore } from "react";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import PageHeader from "../../Components/PageHeader";
import { handleGetAccessToken } from "../../Helpers/auth";
import {
	actions as exportActions,
	store as exportStore,
} from "../../Stores/Export";
import {
	actions as vesselActions,
	store as vesselsStore,
} from "../../Stores/Vessels";
import useFetchExports from "./useFetchExports";

export default () => {
	const navigate = useNavigate();
	const [namespace] = useOutletContext();
	const [showSnackbar, setShowSnackbar] = useState(false);
	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const {
		response: exports,
		isFetchingExports,
		hasFetchedExports,
		fetchExports,
		error: fetchExportsError,
	} = useFetchExports();

	const {
		isFetchingVessels,
		hasFetchedVessels,
		error: fetchVesselsError,
	} = useSyncExternalStore(
		vesselsStore.subscribeToVessels,
		vesselsStore.getVessels,
	);

	const { isExportReady } = useSyncExternalStore(
		exportStore.subscribeToExports,
		exportStore.getExports,
	);

	useEffect(() => {
		if (!isFetchingExports) {
			if (isExportReady) {
				fetchExports(dayjs().subtract(30, "day").toDate(), dayjs().toDate());
				exportActions.setIsExportReady(false);
			} else if (!hasFetchedExports) {
				fetchExports(dayjs().subtract(30, "day").toDate(), dayjs().toDate());
			}
		}
	}, [hasFetchedExports, fetchExports, isExportReady, isFetchingExports]);

	useEffect(() => {
		if (fetchExportsError || fetchVesselsError) {
			setShowSnackbar(true);
		}
	}, [fetchExportsError, fetchVesselsError]);

	useEffect(() => {
		if (!isFetchingVessels && !hasFetchedVessels) {
			handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
				(token) => {
					vesselActions.fetchVessels(token, namespace.code);
				},
			);
		}
	}, [
		getAccessTokenSilently,
		loginWithRedirect,
		isFetchingVessels,
		hasFetchedVessels,
		namespace,
	]);

	const isExpired = (expires) => {
		return expires < new Date().toISOString();
	};

	const renderState = (e, isExpired) => {
		if (isExpired) {
			//to keep padding the same for all grid rows include hidden button
			return (
				<>
					{"Expired"}
					<IconButton sx={{ visibility: "hidden" }}>
						<DownloadForOfflineIcon
							color="primary"
							sx={{ width: 28, height: 28 }}
						/>
					</IconButton>
				</>
			);
		}
		if (e.state === "DONE") {
			return (
				<a download href={e.links[0]}>
					<IconButton disabled={isExpired} title="Ready">
						<DownloadForOfflineIcon
							color="primary"
							sx={{ width: 28, height: 28 }}
						/>
					</IconButton>
				</a>
			);
		}
		return (
			<>
				<IconButton disabled>
					<DownloadingIcon
						color="disabled"
						sx={{ width: 28, height: 28 }}
						title="Preparing... Please wait."
					/>
				</IconButton>
			</>
		);
	};
	return (
		<>
			<PageHeader>Data Exports (Last 30 days)</PageHeader>

			<Grid container p={4}>
				<Grid size={{ xs: 4 }} />
				<Grid size={{ xs: 7 }} />

				<Grid
					size={{ xs: 1 }}
					display="flex"
					alignItems="center"
					justifyContent={"center"}
				>
					<Button
						endIcon={<FileDownloadIcon />}
						onClick={() => navigate(`/newexport?n=${namespace.code}`)}
					>
						New Export
					</Button>
				</Grid>
			</Grid>

			{isFetchingExports || !hasFetchedExports ? (
				<Box
					sx={{
						position: "absolute",
						top: "50vh",
						left: "50vw",
						transform: "translate(-50%, -50%)",
					}}
				>
					<BounceLoader color="#44ABDF" size={80} />
				</Box>
			) : exports.length ? (
				<Stack spacing={2} sx={{ px: 4 }} divider={<Divider flexItem />}>
					<Grid
						container
						sx={{
							color: "#03143d",
							fontWeight: 600,
							fontSize: "1em",
						}}
					>
						<Grid size={{ xs: 4 }} display="flex" alignItems="center">
							Vessel
						</Grid>
						<Grid size={{ xs: 2 }} display="flex" alignItems="center">
							Start
						</Grid>
						<Grid size={{ xs: 2 }} display="flex" alignItems="center">
							End
						</Grid>
						<Grid size={{ xs: 2 }} display="flex" alignItems="center">
							Status
						</Grid>
						<Grid size={{ xs: 2 }} />
					</Grid>
					{exports.map((e) => (
						<Grid
							key={e._id}
							container
							sx={{
								fontStyle: isExpired(e.expires) ? "italic" : "normal",
							}}
						>
							<Grid size={{ xs: 4 }} display="flex" alignItems="center">
								{vesselsStore.getSensorGroupBySensorId(e.sensorId)?.name ??
									"No Name Found"}
							</Grid>
							<Grid size={{ xs: 2 }} display="flex" alignItems="center">
								{dayjs(e.dataStartTime).format("MM/DD/YYYY")}
							</Grid>
							<Grid size={{ xs: 2 }} display="flex" alignItems="center">
								{dayjs(e.dataEndTime).format("MM/DD/YYYY")}
							</Grid>
							<Grid size={{ xs: 2 }} display="flex" alignItems="center">
								{renderState(e, isExpired(e.expires))}
							</Grid>
							<Grid size={{ xs: 2 }} display="flex" />
						</Grid>
					))}
				</Stack>
			) : (
				<Box
					height="50%"
					width="100%"
					alignItems="center"
					display="flex"
					justifyContent="center"
				>
					<h3>No exports found</h3>
				</Box>
			)}
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={showSnackbar}
				autoHideDuration={6000}
				onClose={() => {
					setShowSnackbar(false);
				}}
			>
				<Alert severity={"error"} sx={{ width: "100%" }}>
					Something went wrong. Please try again later.
				</Alert>
			</Snackbar>
		</>
	);
};
